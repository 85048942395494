import { isActive, isPending } from 'Helpers/adActionStatus';
import { getItemSubsetData } from 'Helpers/item';
import isNumber from 'lodash/isNumber';
import { isEmpty } from 'Helpers/objects';
import {
    __MONET_TRANSITION_PARAMS,
    __FEATURE_TYPE_MAP,
    __FEATURE_ALL,
    __FEATURE_AD_UPGRADE,
    __FEATURE_BTT,
    __FEATURE_AUTO_BOOST,
    __FEATURE_LIMITS,
    __FEATURE_USER_PREMIUM,
    __FEATURE_MULTI_LOCATION,
    __FEATURE_TYPE_LIMIT,
    __FEATURE_TYPE_MULTI_LOCATION,
    __FEATURE_SPOTLIGHT,
    __FEATURE_DISCOUNT_TAG,
    __FEATURE_TYPE_DISCOUNT_TAG
} from 'Constants/monetizers';

const prepareAdSubDataModel = (adModel, localStorage) => {
    const itemParams = getItemSubsetData(adModel);

    localStorage.setItem(__MONET_TRANSITION_PARAMS, JSON.stringify(itemParams));
};

export const prepareAdLimitModel = (isAdLimited, adModel, localStorage, isFormMyAds) => {
    const { parameters } = adModel;

    const __POSTING_LIMIT_DRAFT = 'postingLimitDraft';
    // for edit ads not status limited use discount
    const postingLimitDraft = adModel?.monetizationInfo?.limits ? adModel.monetizationInfo.limits : adModel.monetizationInfo.discount;
    const isActivatedDiscountToggle = parameters?.find(item => item.key === 'discount_toggle')?.value;

    postingLimitDraft.isAdLimited = isAdLimited;
    postingLimitDraft.isActivatedDiscountToggle = !isFormMyAds && isActivatedDiscountToggle === 'enabled';
    postingLimitDraft.id = adModel.id;
    postingLimitDraft.paid_discount_tag_available = adModel?.monetizationInfo?.discount?.paid_discount_tag_available ?? false;
    postingLimitDraft.is_multi_location = adModel?.is_multi_location ?? false;

    localStorage.setItem(__POSTING_LIMIT_DRAFT, JSON.stringify(postingLimitDraft));
    prepareAdSubDataModel(adModel, localStorage);

    return postingLimitDraft;
};

export const getSavedAdLimitModel = localStorage => {
    return JSON.parse(localStorage.getItem('postingLimitDraft'));
};

const getDiscountTagRoute = (adLimitModel, params, isMultiLocationAd, dtAvailable) => {
    const isFreeAvailable = adLimitModel?.free_limit_available && adLimitModel?.free_count >= 0;
    const isPaidAvailable = adLimitModel?.paid_available === true;
    const successPostTrialLimit = params ? `${params}&successPostFreeLimitAd=true` : '?successPostFreeLimitAd=true';

    let baseRoute = '';

    if (isFreeAvailable && !isMultiLocationAd) {
        baseRoute = dtAvailable
            ? `/payments/packages/consume/${__FEATURE_TYPE_DISCOUNT_TAG}${successPostTrialLimit}`
            : `/payments/choosePackage/single/${__FEATURE_DISCOUNT_TAG}${successPostTrialLimit}`;
    }
    else if (isPaidAvailable) {
        baseRoute = dtAvailable
            ? `/payments/packages/consume/${isMultiLocationAd ? __FEATURE_TYPE_MULTI_LOCATION : __FEATURE_TYPE_LIMIT}${params}`
            : `/payments/choosePackage/single/${__FEATURE_DISCOUNT_TAG}${params}`;
    }
    else {
        baseRoute = `/payments/choosePackage/single/${isMultiLocationAd ? __FEATURE_MULTI_LOCATION : __FEATURE_LIMITS}${params}`;
    }

    return baseRoute;
};

export const getDiscountEditRoute = (useDiscount, discount_toggle, merge, router) => {
    const params = merge ? '?merge=true' : '';

    let baseRoute = '';

    if (discount_toggle === 'enabled') {
        baseRoute = useDiscount
            ? `/payments/packages/consume/${__FEATURE_TYPE_DISCOUNT_TAG}${params}`
            : `/payments/choosePackage/single/${__FEATURE_DISCOUNT_TAG}${params}`;
    }

    return router.push(baseRoute);
};

export const routeToPostingLimits = (adLimitModel, router, merge, isMultiLocationAd) => {
    if (adLimitModel) {
        const params = merge ? '?merge=true' : '';
        const dt_available = adLimitModel.paid_discount_tag_available;

        if (adLimitModel.isAdLimited && adLimitModel.isActivatedDiscountToggle) {
            router.push(getDiscountTagRoute(adLimitModel, params, isMultiLocationAd, dt_available));
        }
        else if (adLimitModel.isAdLimited) {
            if (adLimitModel.free_limit_available && adLimitModel.free_count >= 0) {
                router.push(`/payments/packages/consume/free/success${params}`);
            }
            else if (adLimitModel.paid_available) {
                router.push(`/payments/packages/consume/${isMultiLocationAd ? __FEATURE_TYPE_MULTI_LOCATION : __FEATURE_TYPE_LIMIT}${params}`);
            }
            else {
                router.push(`/payments/choosePackage/single/${isMultiLocationAd ? __FEATURE_MULTI_LOCATION : __FEATURE_LIMITS}${params}`);
            }
        }
        else if (adLimitModel.free_count >= 0) {
            if (adLimitModel.isActivatedDiscountToggle) {
                router.push(getDiscountTagRoute(adLimitModel, params, isMultiLocationAd, dt_available));
            }
            else {
                router.push(`/payments/packages/consume/free/success${params}`);
            }
        }
        else {
            router.push(`/payments/packages/consume/unlimited/success${params}`);
        }
    }
};

export const routeToPackageConsumption = (router, adModel, localStorage) => {
    prepareAdSubDataModel(adModel, localStorage);
    router.push('/payments/packages/consume/boost');
};

export const canBeFeatured = (loggedUser, { monetizationInfo, status, user_id: itemUserId }) => {
    const radix = 10;

    return monetizationInfo
        && loggedUser
        && parseInt(loggedUser.id, radix) === parseInt(itemUserId, radix)
        && (isActive(status) || isPending(status));
};

export const isAdPostable = limitInfo => {
    return !isEmpty(limitInfo) && (limitInfo.free_limit_available || limitInfo.paid_available);
};

export const checkDiscount = pkg => {
    return pkg.price.amount != 0
        && pkg.price.previousAmount != 0
        && pkg.price.amount < pkg.price.previousAmount;
};

export const calculateDiscount = (prevPrice, currentPrice) => {
    if (prevPrice === 0) {
        return 0;
    }
    return Math.round((prevPrice - currentPrice) / prevPrice * 100, 0); // eslint-disable-line no-magic-numbers
};

export const addDaysToDate = days => {
    const date = new Date();

    date.setDate(date.getDate() + days);
    return date;
};

export const addDaysToDateWithOptions = days => {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + days);

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);

    return formattedDate.replace(',', '').replace(' ', "' ");
};

export const isLocationInfoAvailable = locationInfo => {
    let locationInfoAvailable = false;

    if (locationInfo.additionalTrackingAttributes === null) {
        const { cityId, regionId, subRegionId, cityObj, regionObj, subRegionObj } = locationInfo;

        locationInfoAvailable = (cityId === 0 && regionId === 0)
            || (cityId && cityObj)
            || (regionId && regionObj)
            || (subRegionId && subRegionObj);
    }
    return locationInfoAvailable;
};

export const buildAdditionalAttributes = (locationInfo, locationProps, addOrderId = false) => {
    // add tracking paramater for packages bought at country level
    const additionalAttributes = {};
    const categoryL2Obj = locationProps.allCategories[locationProps?.item?.categoryId];
    const categoryL1Obj = locationProps.allCategories[categoryL2Obj?.parent_id];

    if (categoryL1Obj) {
        additionalAttributes.category_level1_id = categoryL1Obj?.id;
        additionalAttributes.category_level1_name = categoryL1Obj?.name;
    }
    additionalAttributes.category_level2_id = categoryL2Obj?.id;
    additionalAttributes.category_level2_name = categoryL2Obj?.name;

    if (locationInfo.cityObj) {
        additionalAttributes.city_id = locationInfo?.cityObj.id;
        additionalAttributes.city_name = locationInfo?.cityObj.name;
    }
    if (locationInfo.regionObj) {
        additionalAttributes.state_id = locationInfo.regionObj.id;
        additionalAttributes.state_name = locationInfo.regionObj.name;
    }
    if (locationInfo.subRegionObj) {
        additionalAttributes.neighbourhood_id = locationInfo.subRegionObj.id;
        additionalAttributes.neighbourhood_name = locationInfo.subRegionObj.name;
    }
    if (locationProps?.item?.adId) {
        additionalAttributes.item_id = locationProps.item.adId;
    }
    if (locationProps?.item?.status) {
        additionalAttributes.item_status = locationProps.item.status;
    }
    if (locationProps?.item?.imageCount) {
        additionalAttributes.images_count = locationProps?.item?.imageCount;
    }
    if (addOrderId) {
        // SINCE ORDER_ID WAS PRESENT ONLY FOR MULTI SELECTED PACKAGE, THIS FIX IS IMPORTANT FOR ALL TYPES OF PACKAGES TO PASS ORDER_ID
        if (locationInfo?.selectedPackage && locationInfo?.selectedPackage?.orderId) {
            additionalAttributes.order_id = locationInfo.selectedPackage.orderId;
        }
        else if (locationInfo?.selectedMultiplePackage && locationInfo?.selectedMultiplePackage?.orderId) {
            additionalAttributes.order_id = locationInfo.selectedMultiplePackage.orderId;
        }
    }
    return additionalAttributes;
};

function buildLocationParams(locationObj) {
    const { cityId, stateId, neighbourhoodId } = locationObj;

    return {
        cityId: isNumber(parseInt(cityId, 10)) ? parseInt(cityId, 10) : '',
        stateId: isNumber(parseInt(stateId, 10)) ? parseInt(stateId, 10) : '',
        neighbourhoodId: isNumber(parseInt(neighbourhoodId, 10)) ? parseInt(neighbourhoodId, 10) : ''
    };
}

export const fetchLocationDetails = (self, getLocationsById) => {
    let locationFetched = false;
    const { monetTransitionParams, cityObj, regionObj, subRegionObj } = self;
    const { cityId, stateId, neighbourhoodId } = buildLocationParams(monetTransitionParams.locations);

    if (cityId && !cityObj) {
        getLocationsById(cityId);
        locationFetched = true;
    }
    if (stateId && !regionObj) {
        getLocationsById(stateId);
        locationFetched = true;
    }
    if (neighbourhoodId && !subRegionObj) {
        getLocationsById(neighbourhoodId);
        locationFetched = true;
    }
    return locationFetched;
};

export const getPackageGroup = (featureType, origin, isRevamp = false, isDiscountTag = false) => {
    let packageGroup = __FEATURE_TYPE_MAP[featureType] || featureType;

    if (featureType === __FEATURE_AD_UPGRADE) {
        if (origin === 'posting') {
            packageGroup += `,${__FEATURE_SPOTLIGHT}`;
        }
        else {
            packageGroup += `,${__FEATURE_BTT},${__FEATURE_SPOTLIGHT}`;
        }
    }
    else if (featureType === __FEATURE_ALL && isRevamp) {
        packageGroup = `${__FEATURE_LIMITS},${__FEATURE_AD_UPGRADE},${__FEATURE_BTT},${__FEATURE_AUTO_BOOST},${__FEATURE_USER_PREMIUM},${__FEATURE_MULTI_LOCATION},${__FEATURE_SPOTLIGHT},${__FEATURE_DISCOUNT_TAG}`;
    }
    else if (featureType === __FEATURE_ALL) {
        packageGroup = `${__FEATURE_LIMITS},${__FEATURE_AD_UPGRADE},${__FEATURE_DISCOUNT_TAG},${__FEATURE_BTT},${__FEATURE_AUTO_BOOST},${__FEATURE_USER_PREMIUM}`;
    }
    else if (featureType === __FEATURE_LIMITS || featureType === __FEATURE_MULTI_LOCATION) {
        if (isDiscountTag) {
            packageGroup += `,${__FEATURE_DISCOUNT_TAG}`;
        }
    }

    return packageGroup;
};

const categorizeDiscountPackage = pkg => {
    return pkg.features.some(f => f.feature === __FEATURE_TYPE_DISCOUNT_TAG);
};

export const categorizePackage = userPackage => {
    const packages = [];
    const discountPackages = [];

    userPackage.forEach(pkg => {
        if (categorizeDiscountPackage(pkg)) {
            discountPackages.push(pkg);
        }
        else {
            packages.push(pkg);
        }
    });

    return [packages, discountPackages];
};

